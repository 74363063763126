import Container from '@components/Container'
import SEO from '@components/SEO'
import { css } from '@emotion/react'
import { MediaQuery } from '@graphql-types'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { maxWidth, minWidth } from 'src/themes'

export default function Media(props: PageProps<MediaQuery>) {
	const { t } = useTranslation()

	return (
		<Container>
			<SEO title={t('layout:media')} keywords={[`media`]} />
			<h1>{t('layout:media')}</h1>
			<div
				css={css`
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					${minWidth[2]} {
						> div {
							width: 45%;
						}
					}

					> div:first-of-type {
						width: 100%;
					}
				`}>
				{props.data.medias?.nodes?.map((media) => (
					<div
						key={media!._id}
						css={css`
							margin: 2rem 0;
						`}>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href={media!.link!}>
							<GatsbyImage
								image={
									media!.mediaLogoSharp?.childImageSharp
										?.gatsbyImageData
								}
								alt={media!.title!}
							/>
							<h2>
								{media!.title} <span>({media!.date})</span>
							</h2>
							<p>{media!.description}</p>
							<GatsbyImage
								image={
									media!.imageSharp?.childImageSharp
										?.gatsbyImageData
								}
								alt={media!.title!}
							/>
						</a>
					</div>
				))}
			</div>
		</Container>
	)
}

export const query = graphql`
	query Media($language: String!) {
		...TranslationFragment
		medias: allCockpitMediaCollection(
			filter: { _lang: { eq: $language } }
			sort: { fields: date, order: DESC }
		) {
			nodes {
				_id
				title
				link
				description
				date
				mediaLogo {
					path
				}
				mediaLogoSharp {
					childImageSharp {
						gatsbyImageData(width: 150)
					}
				}
				image {
					path
				}
				imageSharp {
					childImageSharp {
						gatsbyImageData(width: 1024, height: 1024)
					}
				}
			}
		}
	}
`
